import { admin2 } from '@/api/instance'

export const GetEvaluateConfig = ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/evaluateConfig`,
  })
}

export const UpdateEvaluateConfig = ({
  shopId,
  reservationCompleteEnable,
  reservationCompleteLink,
  evaluateLink,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/evaluateConfig`,
    data: {
      reservationCompleteEnable,
      reservationCompleteLink,
      evaluateLink,
    },
  })
}
