<template>
  <div class="reservation-parameter">
    <PageTitle
      icon="chevron_left"
      title="評價通知設定"
      hideBtn
      style="padding-bottom: 24px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <!-- ANCHOR 時段設定 -->
    <section :v-loading="loading" class="settings-section section-block">
      <SectionTitle
        title="通知設定"
        :hideBtn="true"
        btn="編輯"
      />
      <div>
        <el-form
          label-position="left"
          label-width="250px"
        >
          <el-form-item label="完成預約 - 發送通知">
            <el-switch
              v-model="formData.reservationCompleteEnable"
              style="margin-right: 20px"
              active-text="開啟"
              inactive-text="關閉"
              @change="onChangeEnabledEvaluate"
            />
          </el-form-item>
        </el-form>
      </div>
    </section>

    <!-- ANCHOR GMB商家評論設定 -->
    <section :v-loading="loading" class="section-block settings-section">
      <SectionTitle
        title="商家評論連結"
        btn="編輯"
        @edit="Object.assign(editFormData, formData); showEditLinkDialog = true"
      />
      <div>
        <el-form
          :model="formData"
          label-position="left"
          label-width="250px"
        >
          <el-form-item label="滿意度調查連結">
            <span data-cy="time-space-view" class="content">{{
              formData.evaluateLink
            }}</span>
          </el-form-item>
<!--          <el-form-item label="評論連結">-->
<!--            <span data-cy="time-space-view" class="content">{{-->
<!--              formData.reservationCompleteLink-->
<!--            }}</span>-->
<!--          </el-form-item>-->
        </el-form>
      </div>

      <!-- Dialog -->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="showEditLinkDialog"
        title="連結設定"
      >
        <el-form
          ref="form"
          :model="editFormData"
          :rules="editFormRules"
          label-position="top"
        >
          <el-form-item label="滿意度調查連結" prop="evaluateLink">
            <el-input
              v-model="editFormData.evaluateLink"
            />
          </el-form-item>
        </el-form>

        <div slot="footer">
          <el-button plain @click="showEditLinkDialog = false">取消</el-button>
          <el-button type="primary" @click="onUpdateGmbEvaluate">儲存</el-button>
        </div>
      </el-dialog>
    </section>
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import {
  GetEvaluateConfig,
  UpdateEvaluateConfig,
} from '@/api/evaluate/evaluate'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { noEmptyRules } from '@/validation'

export default {
  name: 'EvaluateParameter',
  components: {
    PageTitle,
    SectionTitle,
  },

  data: () => ({
    loading: false,

    firstSetting: false,
    showEditLinkDialog: false,

    editFormData: {
      reservationCompleteLink: '',
      evaluateLink: '',
    },
    editFormRules: {
      // reservationCompleteLink: noEmptyRules('請填寫評論連結'),
      evaluateLink: noEmptyRules('請填寫滿意度調查連結'),
    },
    formData: {
      reservationCompleteMsg: '',
      reservationCompleteEnable: false,
      reservationCompleteLink: '',
      evaluateLink: '',
    },
  }),

  computed: {
    ...mapGetters(['shop', 'userFeatures', 'userPlanFeature']),
  },

  async mounted () {
    await this.GetEvaluateConfig()
  },

  methods: {
    async GetEvaluateConfig () {
      this.loading = true
      const [res, error] = await GetEvaluateConfig({
        shopId: this.shop,
      })
      this.loading = false
      if (error) {
        this.$message.error(error.message)
        return
      }
      const {
        reservationCompleteEnable,
        reservationCompleteLink,
        evaluateLink,
      } = res
      this.firstSetting = !reservationCompleteEnable && !reservationCompleteLink && !evaluateLink
      console.log(res)
      if (this.firstSetting) {
        res.reservationCompleteLink = 'https://www.google.com/'
        res.evaluateLink = 'https://www.google.com/'
      }
      this.formData = res
    },
    async onChangeEnabledEvaluate () {
      this.loading = true
      const [_, error] = await UpdateEvaluateConfig({
        ...this.formData,
        shopId: this.shop,
      })
      this.loading = false
      if (error) {
        this.$message.error(error.message)
        return
      }
      this.$message.success('更新成功')
    },
    async onUpdateGmbEvaluate () {
      Object.assign(this.formData, this.editFormData)
      const [_, error] = await UpdateEvaluateConfig({
        ...this.formData,
        shopId: this.shop,
      })
      if (error) {
        this.$message.error(error.message)
        return
      }
      this.$message.success('更新成功')
      this.showEditLinkDialog = false
    },
  },
}
</script>

<style scoped lang="scss">
span {
  font-weight: 400;
  color: #5e5e5e;
}

.sub-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;

  .title-hint {
    @apply mr-[5px] text-primary-100;
  }
}

.settings-item {
  margin-bottom: 16px;
}
</style>
